import React, { Component } from 'react';
import {
  FormGroup,
  Label,
  Input,
  Form,
  Col,
  Row,
} from 'reactstrap';
import Button from 'components/CustomButton/CustomButton.jsx';
import Select from 'react-select';

class FiltrosDespesa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: '',
      documento: '',
      respondidoOptions: [
        { value: 'sim', label: 'Sim' },
        { value: 'nao', label: 'Não' }
      ],
      respondido: '',
      empresa: '',
    };
  }

  async componentDidMount() {
    await this.getAllUsers();
  }

  getAllUsers = async (payload) => {
    try {
      const { data } = await this.props.api
        .getAllUsers({ usuarioDesconsiderar: this.props.usuarioDesconsiderar });
      this.setState({
        usuariosOptions: data.map((usuario) => ({ value: usuario.id, label: usuario.name })),
      });
    } catch (err) {
      console.log(err);
    }
  }


  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleMultiSelectChange = (property) => (evt) => {
    const value = evt ? evt : '';
    this.setState({
      [property]: value,
    });
  }

  handleSelectChange = (property) => (evt) => {
    const event = evt ? evt : ''
    this.setState({
      [property]: event.value && !event.info ? event.value : event,
    });
  }

  handleSubmit = async (event) => {
    try {
      event.preventDefault();

      const sendObject = {
        usuarioId: this.state.nome,
        respondido: this.state.respondido,
        empresa: this.state.empresa,
        username: this.state.documento,
      };

      this.props.loadData(0, sendObject);
      this.props.modal();
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit} >
        <Row>
          <Col md="6">
            <FormGroup>
              <Label for="nome">Nome</Label>
              <Select
                options={this.state.usuariosOptions}
                onChange={this.handleSelectChange('nome')}
                placeholder="Selecione"
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="respondido">Respondido</Label>
              <Select
                options={this.state.respondidoOptions}
                onChange={this.handleSelectChange('respondido')}
                placeholder="Selecione"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label for="empresa">Empresa</Label>
              <Input
                type="text"
                name="empresa"
                id="empresa"
                onChange={this.handleInputChange}
                placeholder="Digite aqui"
                disabled={this.props.detail}
                defaultValue={this.state.empresa}
                className="upper-case-input"
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="cnpj">CNPJ/CPF</Label>
              <Input
                type="number"
                name="documento"
                id="documento"
                onChange={this.handleInputChange}
                placeholder="Digite aqui"
                disabled={this.props.detail}
                defaultValue={this.state.documento}
              />
            </FormGroup>
          </Col>
        </Row>
        <Button color="success" round type="submit">
          Filtrar  <i className="fas fa-filter"></i>
        </Button>
      </Form>
    );
  }
}

export default FiltrosDespesa;