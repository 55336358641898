import React from "react";

import {
  FormGroup,
  Label,
  Input,
  Form,
  Col,
  Row,
  Button,
} from "reactstrap";

class NovoUsuarioForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: props.usuario,
      disableSubmitButton: false,
      nome: '',
      username: '',
      email: '',
      mostrarSenha: false,
      empresa: '',
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSelectChange = param => evt => {
    this.setState({
      [param]: evt.value,
    });
  }

  handleMoneyInputChange = (event) => {
    const name = event.target.name;

    if (event.money) {
      this.setState({ [name]: event.money.actualNumber });
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.disableSubmitButton();


    try {
      const sendObject = {
        name: this.state.nome,
        username: this.state.username,
        email: this.state.email,
        password: this.state.senha || 'evoluire',
        empresa: this.state.empresa,
      }

      let response;
      if (!this.props.edit) {
        response = await this.props.api.createUser(sendObject);
      } else {
        response = await this.props.api.updateUser(this.props.usuario.id, sendObject);
      }

      if (response.data.message) {
        this.props.abrirNotificacaoErro('Usuário já cadastrado.');
      } else {
        this.props.abrirNotificacaoSucesso();
        this.props.loadData(0);
        this.props.modal();
      }
    } catch (err) {
      this.disableSubmitButton();
      console.log(err);
    }
  }

  disableSubmitButton = () => {
    this.setState((prevState) => ({ disableSubmitButton: !prevState.disableSubmitButton }));
  }

  handleMostrarSenhaCheckBox = (evt) => {
    const target = evt.target
    this.setState({
      mostrarSenha: target.checked,
    })
  }

  handleUsernameBlur = async () => {
    const { data } = await this.props.api.getLastCompanyByUser(this.state.username);
    if (data && data[0].empresa) {
      this.setState({
        empresa: data[0].empresa,
      });
      return this.props.abriNotificacaoInfo();
    }
  }

  render() {
    const { usuario } = this.state;
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="nome">Nome Completo</Label>
                <Input
                  type="text"
                  name="nome"
                  id="nome"
                  onChange={this.handleInputChange}
                  defaultValue={usuario.name}
                  required={true}
                  placeholder="Digite aqui"
                  autoComplete="new-text"
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="username">Username (CPF)</Label>
                <Input
                  type="text"
                  name="username"
                  id="username"
                  onChange={this.handleInputChange}
                  onBlur={this.handleUsernameBlur}
                  defaultValue={usuario.username}
                  placeholder="Digite aqui"
                  required={true}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {!this.props.edit && (
              <Col md="6">
                <FormGroup>
                  <Label for="password">Senha</Label>
                  <Input
                    type={this.state.mostrarSenha ? 'text' : 'password'}
                    name="senha"
                    id="senha"
                    onChange={this.handleInputChange}
                    autoComplete="new-password"
                    placeholder="Digite aqui"
                    defaultValue="evoluire"
                    required={true}
                  />
                </FormGroup>
              </Col>
            )}
            <Col md="6">
              <FormGroup>
                <Label for="email">E-mail</Label>
                <Input
                  type="e-mail"
                  name="email"
                  id="email"
                  onChange={this.handleInputChange}
                  defaultValue={usuario.email}
                  placeholder="Digite aqui"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {!this.props.edit && (
              <Col md="6">
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox"
                      name="mostrarsenha"
                      onChange={this.handleMostrarSenhaCheckBox}
                    />{' '}
                      Mostrar senha
                      <span className="form-check-sign">
                      <span className="check"></span>
                    </span>
                  </Label>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="empresa">Empresa</Label>
                <Input
                  type="text"
                  name="empresa"
                  id="empresa"
                  onChange={this.handleInputChange}
                  placeholder="Digite aqui"
                  disabled={this.props.detail}
                  value={usuario.empresa || this.state.empresa}
                  className="upper-case-input"
                />
              </FormGroup>
            </Col>
          </Row>
          {
            !this.props.detail && (
              <Button
                className="btn btn-primary"
                round="true"
                type="submit"
                disabled={this.state.disableSubmitButton}
              >
                Salvar  <i className="fas fa-save"></i>
              </Button>
            )
          }
        </Form >
      </>
    );
  }
}

export default NovoUsuarioForm;