import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Media from "react-media";
import ResultadoProdutivoPDF from "./ResultadoProdutivoPDF";
import ResultadoQuedaPDF from "./ResultadoQuedaPDF";

class FiltrosDespesa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadData: false,
      produtivoPDFModal: false,
      quedaPDFModal: false,
    };
  }

  async componentDidMount() {
    await this.getRespostas();
  }

  getRespostas = async () => {
    try {
      const { data } = await this.props.api.userAnswers(this.props.username);
      if (data.lifoData?.length) {
        const { lifoData } = data;
        this.handleLifoTotais(lifoData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleLifoTotais = (lifoData) => {
    let quedaArray = [];
    let produtivoArray = [];

    // transformar em um array chave valor
    const values = Object.entries(lifoData[0]);
    values.forEach((value) => {
      if (value[0].includes("queda")) {
        quedaArray.push(value);
      } else {
        produtivoArray.push(value);
      }
    });

    produtivoArray = this.preencherValores(produtivoArray);
    quedaArray = this.preencherValores(quedaArray);

    const estilosProdutivo = produtivoArray.sort((a, b) => a.value - b.value);
    const estilosQueda = quedaArray.sort((a, b) => a.value - b.value);

    const primeiroEstiloProdutivo = estilosProdutivo[3];
    const primeiroEstiloQueda = estilosQueda[3];
    const segundoEstiloProdutivo = estilosProdutivo[2];
    const segundoEstiloQueda = estilosQueda[2];
    const terceiroEstiloProdutivo = estilosProdutivo[1];
    const terceiroEstiloQueda = estilosQueda[1];
    const quartoEstiloProdutivo = estilosProdutivo[0];
    const quartoEstiloQueda = estilosQueda[0];

    this.setState({
      primeiroEstiloProdutivo,
      primeiroEstiloQueda,
      segundoEstiloProdutivo,
      segundoEstiloQueda,
      terceiroEstiloProdutivo,
      terceiroEstiloQueda,
      quartoEstiloProdutivo,
      quartoEstiloQueda,
      loadData: true,
    });
  };

  preencherValores = (listBase) => {
    const estilos = this.generateEstilosStructure();

    for (const item of listBase) {
      if (item[0].includes("d_a")) {
        estilos[0].value = item[1];
        continue;
      }
      if (item[0].includes("t_c")) {
        estilos[1].value = item[1];
        continue;
      }
      if (item[0].includes("m_c")) {
        estilos[2].value = item[1];
        continue;
      }
      estilos[3].value = item[1];
    }
    return estilos;
  };

  generateEstilosStructure = () => {
    return [
      { label: "D/A", value: 0 },
      { label: "T/C", value: 0 },
      { label: "M/C", value: 0 },
      { label: "A/N", value: 0 },
    ];
  };

  handleTdTitle = (estilo) => {
    if (estilo === "A/N") {
      return "ADAPTA E NEGOCIA";
    }
    if (estilo === "M/C") {
      return "MANTÉM E CONSERVA";
    }
    if (estilo === "T/C") {
      return "TOMA E CONTROLA";
    }
    return "DÁ E APÓIA";
  };

  handleQuedaPDFModal = () => {
    this.setState({
      quedaPDFModal: !this.state.quedaPDFModal,
    });
  };

  handleProdutivoPDFModal = () => {
    this.setState({
      produtivoPDFModal: !this.state.produtivoPDFModal,
    });
  };

  handleCloseProdutivoPdfModal = () => {
    this.setState({
      produtivoPDFModal: !this.state.produtivoPDFModal,
    });
  };

  handleCloseQuedaPdfModal = () => {
    this.setState({
      quedaPDFModal: !this.state.quedaPDFModal,
    });
  };

  render() {
    return (
      <div>
        {this.state.loadData && (
          <>
            <Table style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th></th>
                  <th>Desempenho Produtivo</th>
                  <th>Queda de desempenho</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1º Estilo</td>
                  <td
                    title={this.handleTdTitle(
                      this.state.primeiroEstiloProdutivo.label
                    )}
                  >
                    {`${this.state.primeiroEstiloProdutivo.label} - ${this.state.primeiroEstiloProdutivo.value}`}
                  </td>
                  <td
                    title={this.handleTdTitle(
                      this.state.primeiroEstiloQueda.label
                    )}
                  >
                    {`${this.state.primeiroEstiloQueda.label} - ${this.state.primeiroEstiloQueda.value}`}
                  </td>
                </tr>
                <tr>
                  <td>2º Estilo</td>
                  <td
                    title={this.handleTdTitle(
                      this.state.segundoEstiloProdutivo.label
                    )}
                  >
                    {`${this.state.segundoEstiloProdutivo.label} - ${this.state.segundoEstiloProdutivo.value}`}
                  </td>
                  <td
                    title={this.handleTdTitle(
                      this.state.segundoEstiloQueda.label
                    )}
                  >
                    {`${this.state.segundoEstiloQueda.label} - ${this.state.segundoEstiloQueda.value}`}
                  </td>
                </tr>
                <tr>
                  <td>3º Estilo</td>
                  <td
                    title={this.handleTdTitle(
                      this.state.terceiroEstiloProdutivo.label
                    )}
                  >
                    {`${this.state.terceiroEstiloProdutivo.label} - ${this.state.terceiroEstiloProdutivo.value}`}
                  </td>
                  <td
                    title={this.handleTdTitle(
                      this.state.terceiroEstiloQueda.label
                    )}
                  >
                    {`${this.state.terceiroEstiloQueda.label} - ${this.state.terceiroEstiloQueda.value}`}
                  </td>
                </tr>
                <tr>
                  <td>4º Estilo</td>
                  <td
                    title={this.handleTdTitle(
                      this.state.quartoEstiloProdutivo.label
                    )}
                  >
                    {`${this.state.quartoEstiloProdutivo.label} - ${this.state.quartoEstiloProdutivo.value}`}
                  </td>
                  <td
                    title={this.handleTdTitle(
                      this.state.quartoEstiloQueda.label
                    )}
                  >
                    {`${this.state.quartoEstiloQueda.label} - ${this.state.quartoEstiloQueda.value}`}
                  </td>
                </tr>
              </tbody>
            </Table>
            <Media
              query="(min-width: 599px)"
              render={() => (
                <>
                  <Button
                    className="margim-button-pdf"
                    color="success"
                    type="button"
                    onClick={this.handleProdutivoPDFModal}
                  >
                    PRODUTIVO PDF <i className="fas fa-file-pdf"></i>
                  </Button>
                  <Button
                    className="custom-margim-left-button-pdf custom2-margim-left-button-pdf"
                    color="danger"
                    type="button"
                    onClick={this.handleQuedaPDFModal}
                  >
                    QUEDA PDF <i className="fas fa-file-pdf"></i>
                  </Button>
                </>
              )}
            />
          </>
        )}
        <Modal
          className="new-expense-modal"
          isOpen={this.state.produtivoPDFModal}
          toggle={this.handleCloseProdutivoPdfModal}
        >
          <ModalHeader toggle={this.handleCloseProdutivoPdfModal}>
            Desempenho Produtivo
          </ModalHeader>
          <ModalBody>
            <ResultadoProdutivoPDF
              estiloProdutivo={this.state.primeiroEstiloProdutivo}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={this.handleCloseProdutivoPdfModal}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className="new-expense-modal"
          isOpen={this.state.quedaPDFModal}
          toggle={this.handleCloseQuedaPdfModal}
        >
          <ModalHeader toggle={this.handleCloseQuedaPdfModal}>
            Queda de desempenho
          </ModalHeader>
          <ModalBody>
            <ResultadoQuedaPDF estiloQueda={this.state.primeiroEstiloQueda} />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.handleCloseQuedaPdfModal}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default FiltrosDespesa;
