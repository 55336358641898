import React from "react";
import Cookies from 'js-cookie';

import "./style.css";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Api from '../../config/Api';

export default class Pergunta extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      token: Cookies.get('TOKEN'),
      formId: 1,
      quiz: [],
      loading: true,
    };

    this.api = new Api(this.state.token, (err) => {
      if (err.response.status === 401)
        this.props.history.push({
          pathname: '/login',
          state: { previousURL: this.props.location.pathname }
        });
      return Promise.reject(err);
    });
  }

  async componentDidMount() {
    await this.getQuiz();
    setTimeout(() => {
      this.setState({
        loading: false,
      })
    }, 400);
  }

  getQuiz = async () => {
    try {
      const response = (await this.api.getQuiz(this.state.formId));
      const sortedQuiz = response.data.sort((a, b) => a.number - b.number);
      this.setState({
        quiz: sortedQuiz,
      });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <>
        <div className="content">
          <button
            className="btn btn-primary novo-cadastro-button-margin"
          // onClick={() => this.abrirModalNovaConta()}
          >
            <i className="fas fa-folder-plus"></i> Nova Pergunta
        </button>
          <Row>
            <Col className="ml-auto mr-auto" md="11">
              <Card className="card-upgrade">
                <CardHeader className="text-center">
                  <CardTitle tag="h4">Perguntas
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Ações</th>
                        <th>Descrição</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <UncontrolledDropdown group>
                            <DropdownToggle caret color="primary">
                              <i className="fas fa-cog"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                title="Visualizar"
                              // onClick={() => this.abrirModalDetail(conta.id)}
                              >
                                <i className="fas fa-eye"></i> Visualizar
                                </DropdownItem>
                              <DropdownItem
                                title="Editar"
                              // onClick={() => this.abrirModalEdit(conta.id)}
                              >
                                <i className="fas fa-edit"></i> Editar
                                </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                        <td>

                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
