import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

class PaginaInicial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exemploModal: false,
    };
  }

  abrirExemploModal = () => {
    this.setState({
      exemploModal: !this.state.exemploModal,
    });
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col className="ml-auto mr-auto" md="11">
              <Card className="card-upgrade">
                <CardHeader className="text-center">
                  <CardTitle>
                    <h4>
                      Seja bem-vindo ao seu LIFO <i className="far fa-registered registered-icon-inicial" /> -  Admin
                    </h4>
                    <h6>LIFO <i className="far fa-registered registered-icon-inicial" /> - LIFE ORIENTATION</h6>
                  </CardTitle>
                  {/* <p className="paragrafo-inicial">
                    Os autores <strong>Dr. Stuart Atkins</strong> e <strong>Dr. Allan Katcher</strong> desenvolveram este método de pesquisa
                    com a finalidade de ajudar as pessoas a entender suas preferências comportamentais, como melhorar a si mesmas e melhorar
                    os relacionamentos, estudando a interação dos estilos e pontos fortes de cada um. <br />
                  </p> */}
                </CardHeader>
                <CardBody>
                  
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default PaginaInicial;
