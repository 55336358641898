import Inicio from '../views/Inicio/PaginaInicial';
import Pergunta from '../views/Pergunta/Pergunta';
import Usuario from '../views/Usuarios/Usuario';

const routes = [
  {
    path: "/home",
    name: "Início",
    icon: "fas fa-home",
    component: Inicio,
    visible: true,
  },
  {
    path: "/usuarios",
    name: "Usuários",
    icon: "fas fa-user-edit",
    component: Usuario,
    visible: true,
  },
  {
    path: "/questionario",
    name: "Perguntas",
    icon: "fas fa-tasks",
    component: Pergunta,
    visible: false,
  },
  {
    redirect: true, path: '/', pathTo: '/home', name: 'Home',
  },
];
export default routes;
