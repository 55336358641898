import React from "react";
import Cookies from "js-cookie";
import moment from "moment";

import "./style.css";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Api from "../../config/Api";
import NovoUsuarioForm from "./NovoUsuarioForm";
import Filtros from "./Filtros";
import ResultadoModal from "./ResultadoModal";
import Notification from "../../components/Notification/Notification";
import confirm from "reactstrap-confirm";
import stringHelper from "../../utils/CheckDuplicity/stringHelper";

export default class Pergunta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: Cookies.get("TOKEN"),
      usuarioDesconsiderar: Cookies.get("USERNAME"),
      usuarios: [],
      usuario: {},
      edit: false,
      usuarioModal: false,
      filtered: false,
      filterModal: false,
      dataLoaded: false,
    };

    this.api = new Api(this.state.token, (err) => {
      if (err.response.status === 401)
        this.props.history.push({
          pathname: "/login",
          state: { previousURL: this.props.location.pathname },
        });
      return Promise.reject(err);
    });
  }

  async componentDidMount() {
    await this.getUsers(0, {}, {}, true);
  }

  getUsers = async (offsetParams, payload, filtersOptionsParam, dataLoaded) => {
    try {
      if (!dataLoaded) {
        this.setState({
          loadingMore: true,
        });
      }
      const newPayload = { ...payload };
      newPayload.usuarioDesconsiderar = this.state.usuarioDesconsiderar;
      const offsetToUse =
        offsetParams !== undefined ? offsetParams : this.state.offset;

      const { data } = await this.api.getUsers(10, offsetToUse, newPayload);
      
      let usuariosConcat = [];
      if (offsetToUse !== 0) {
        usuariosConcat = usuariosConcat.concat(this.state.usuarios);
      }
      usuariosConcat = usuariosConcat.concat(data.rows);
      
      const newOffset = offsetToUse + data.rows.length;
      this.setState({
        usuarios: usuariosConcat,
        offset: newOffset,
        count: data.count,
        loadingMore: false,
        dataLoaded: true,
        filtersOptions: filtersOptionsParam || {},
        filters: payload,
      });
    } catch (err) {
      console.log(err);
    }
  };

  openEditModal = async (userId) => {
    try {
      const { data } = await this.api.singleUser(userId);
      this.setState({
        usuarioModal: !this.state.usuarioModal,
        usuario: data,
        edit: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleUserModal = () => {
    this.setState({
      usuarioModal: !this.state.usuarioModal,
      usuario: {},
      edit: false,
    });
  };

  handleResultadoModal = (username) => {
    this.setState({
      resultadoModal: !this.state.resultadoModal,
      username,
      edit: false,
    });
  };

  handleRespondidoCol = (respondido) => {
    if (!respondido) {
      return <span className="badge font-badge badge-red">Não</span>;
    }
    return <span className="badge badge-success font-badge">Sim</span>;
  };

  handleCancelUser = async (usuarioId) => {
    const confirmation = await confirm({
      title: "Atenção",
      message: `O usuário será excluído. Tem certeza que deseja continuar?`,
      confirmText: "Sim",
      cancelText: "Não",
      confirmColor: "success",
    });
    if (confirmation) {
      const { data } = await this.api.deleteUser(usuarioId);
      if (data.status) {
        this.getUsers(0);
        this.abrirNotificacaoSucesso(data.message);
      } else {
        this.abrirNotificacaoErro("Erro ao excluir usuário");
      }
    }
  };

  abrirNotificacaoSucesso = (message) =>
    Notification.createNotification(
      "success",
      message ? message : "Operação realizada com sucesso!"
    );

  abrirNotificacaoErro = (message) =>
    Notification.createNotification("error", message);

  abriNotificacaoInfo = () =>
    Notification.createNotification(
      "info",
      "Uma empresa foi preenchida automáticamente!",
      "Aviso"
    );

  toggleFilterModal = () => {
    this.setState({
      filterModal: !this.state.filterModal,
    });
  };

  removeFilter = () => {
    this.setState(
      {
        filtered: false,
      },
      () => this.getUsers(0)
    );
  };

  loadData = (offset, payload, filtersOptions) => {
    const newPayload = payload || this.state.filters;
    const newFiltersOptions = filtersOptions || this.state.filtersOptions;
    const filtered =
      payload ||
      (this.state.filters && Object.keys(this.state.filters).length !== 0)
        ? true
        : false;
    this.setState(
      {
        filtered,
        dataLoaded: !payload ? true : false,
      },
      () => this.getUsers(offset, newPayload, newFiltersOptions)
    );
  };

  render() {
    return (
      <>
        <div className="content">
          {this.state.dataLoaded ? (
            <>
              <button
                className="btn btn-primary novo-cadastro-button-margin"
                onClick={() => this.handleUserModal()}
              >
                <i className="fas fa-folder-plus"></i> Novo Usuário
              </button>
              <Row>
                <Col className="ml-auto mr-auto" md="11">
                  <Card className="card-upgrade">
                    <CardHeader className="text-center">
                      <CardTitle tag="h4">Usuários</CardTitle>
                      <Row>
                        <Col className="flex">
                          <Button
                            className="filter-margin"
                            color="primary"
                            outline
                            onClick={this.toggleFilterModal}
                            size="sm"
                          >
                            <i className="fas fa-filter"></i> Filtros
                          </Button>
                          {this.state.filtered && (
                            <Button
                              color="primary"
                              outline
                              onClick={this.removeFilter}
                              size="sm"
                            >
                              <i className="fas fa-trash"></i> Limpar
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Table responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Ações</th>
                            <th>Nome</th>
                            <th className="text-center">CPF</th>
                            <th>Respondido</th>
                            <th>Data criação</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.usuarios.map((usuario) => {
                            return (
                              <tr key={usuario.id}>
                                <td>
                                  <UncontrolledDropdown group>
                                    <DropdownToggle caret color="primary">
                                      <i className="fas fa-cog"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        title="Editar"
                                        onClick={() =>
                                          this.openEditModal(usuario.id)
                                        }
                                      >
                                        <i className="fas fa-edit"></i> Editar
                                      </DropdownItem>
                                      {usuario.respondido && (
                                        <DropdownItem
                                          title="Resultado"
                                          onClick={() =>
                                            this.handleResultadoModal(usuario.username)
                                          }
                                        >
                                          <i className="fas fa-poll"></i>{" "}
                                          Resultado
                                        </DropdownItem>
                                      )}
                                      <DropdownItem
                                        title="Excluir"
                                        onClick={() =>
                                          this.handleCancelUser(usuario.id)
                                        }
                                      >
                                        <i className="fas fa-trash"></i> Excluir
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                                <td>{usuario.name}</td>
                                <td className="text-center">
                                  {stringHelper(usuario.username)}
                                </td>
                                <td>
                                  {this.handleRespondidoCol(usuario.respondido)}
                                </td>
                                <td>
                                  {moment(usuario.dataCriacao).format(
                                    "DD-MM-YYYY HH:mm"
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </CardBody>
                    <div className="show-more-center-table-pagined">
                      {this.state.loadingMore ? (
                        <div className="load-spinner-center-table-pagined">
                          <Spinner size="sm" color="primary" />{" "}
                        </div>
                      ) : (
                        <Button
                          color="primary"
                          id="mostrarMaisBtn"
                          outline
                          onClick={() => this.loadData()}
                          disabled={
                            this.state.usuarios.length ===
                            parseInt(this.state.count)
                          }
                          size="sm"
                        >
                          Mostrar Mais
                        </Button>
                      )}
                    </div>
                    <div className="info-pagination-pagination">
                      <h3>
                        Mostrando{" "}
                        {this.state.usuarios && this.state.usuarios.length} de{" "}
                        {this.state.count} {"Usuários"}
                      </h3>
                    </div>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <div className="usuarios-spinner">
              <Spinner
                style={{
                  width: "7rem",
                  height: "7rem",
                }}
                color="primary"
              />
            </div>
          )}
        </div>
        <Modal
          className="new-expense-modal"
          isOpen={this.state.usuarioModal}
          toggle={this.openEditModal}
        >
          <ModalHeader toggle={this.handleUserModal}>Usuário</ModalHeader>
          <ModalBody>
            <NovoUsuarioForm
              usuario={this.state.usuario}
              edit={this.state.edit}
              api={this.api}
              loadData={this.getUsers}
              modal={this.handleUserModal}
              abrirNotificacaoSucesso={this.abrirNotificacaoSucesso}
              abriNotificacaoInfo={this.abriNotificacaoInfo}
              abrirNotificacaoErro={this.abrirNotificacaoErro}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.handleUserModal}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className="new-expense-modal"
          isOpen={this.state.filterModal}
          toggle={this.toggleFilterModal}
        >
          <ModalHeader toggle={this.toggleFilterModal}>Filtros</ModalHeader>
          <ModalBody>
            <Filtros
              api={this.api}
              modal={this.toggleFilterModal}
              loadData={this.loadData}
              abriNotificacaoSucesso={this.abriNotificacaoSucesso}
              usuarioDesconsiderar={this.state.usuarioDesconsiderar}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleFilterModal}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          className="new-expense-modal"
          isOpen={this.state.resultadoModal}
          toggle={this.handleResultadoModal}
        >
          <ModalHeader toggle={this.handleResultadoModal}>Resultado</ModalHeader>
          <ModalBody>
            <ResultadoModal
              api={this.api}
              modal={this.handleResultadoModal}
              username={this.state.username}
              abriNotificacaoSucesso={this.abriNotificacaoSucesso}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.handleResultadoModal}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
