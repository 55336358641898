import axios from 'axios';

export default class Api {
  constructor(token, onError) {
    this.axios = axios.create({
      baseURL: 'https://api-lifo.evoluire.com.br',
      // baseURL: 'http://localhost:3333',
      headers: {
        Authorization: token,
      },
    });

    this.axios.interceptors.response.use((response) => response, onError);
  }

  // Login

  login(username, password, admin) {
    return this.axios.post('/login', { username, password, admin });
  }

  validateToken(token) {
    return this.axios.post('/usuario/check-token', { token });
  }

  // User

  getAllUsers(payload) {
    return this.axios.post('/usuario/all-users', payload);
  }

  getUsers = async (limit = 10, offset = 0, filters) => {
    try {
      const response = await this.axios.get(`/usuario/${filters.usuarioDesconsiderar}?offset=${offset}&limit=${limit}`, {
        params: {
          ...filters,
        }
      });
      return response.data;
    } catch (error) {
      return { error };
    }
  }

  getSingleUser(username) {
    return this.axios.post('/usuario/show', { username, });
  }

  singleUser(id) {
    return this.axios.get(`/usuario/show/${id}`);
  }

  createUser(user) {
    return this.axios.post('/usuario/create', user)
  }

  updateUser(id, user) {
    return this.axios.put(`/usuario/edit/${id}`, user);
  }

  deleteUser(id) {
    return this.axios.delete(`/usuario/delete/${id}`);
  }

  getLastCompanyByUser() {
    return this.axios.post('/usuario/get-last-company');
  }

  userAnswers(usuario) {
    return this.axios.post('/questionario/user-answers', { usuario });
  }

  //  Form 

  getQuiz(id) {
    return this.axios.get(`/ questionario / mount / ${id}`);
  }

  answerQuiz(payload) {
    return this.axios.post('/questionario/answer', payload);
  }

  userAnswers(usuario) {
    return this.axios.post('/questionario/user-answers', { usuario });
  }

  checkRespondido(usuario) {
    return this.axios.post('/usuario/check-respondido', { usuario });
  }

  getUserLifoDados(username) {
    return this.axios.post('/questionario/user-data-info', { username });
  }
}