import React from "react";
import { Form, FormGroup, Input } from 'reactstrap';
import Cookies from 'js-cookie';
import Button from '../../components/CustomButton/CustomButton';
import logoImg from 'logoAtualizadoBranco.png';
import Notification from '../../components/Notification/Notification';

import Api from '../../config/Api';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: Cookies.get('TOKEN'),
      username: '',
      password: '',
    }
    this.api = new Api(this.state.token);
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  submitLogin = async (e) => {
    e.preventDefault();
    try {
      const admin = true;
      const { data } = await this.api.login(this.state.username, this.state.password, admin);
      if (data.token) {
        this.clearOldCookies();
        Cookies.set('TOKEN', data.token);
        Cookies.set('USUARIO', data.name);
        Cookies.set('USERNAME', data.username);
        this.props.history.push({
          pathname: '/home',
        });
      } else if (data.code) {
        this.abrirNotificacaoErro('Usuário ou senha inválido.')
      } else {
        this.abrirNotificacaoErro('Usuário não cadastrado.')
      }
    } catch (error) {
      console.log(error)
      this.abrirNotificacaoErro('Erro de conexão.')
    }
  }

  clearOldCookies = () => {
    Cookies.remove('USUARIO');
  }

  abrirNotificacaoErro = (message) => Notification
    .createNotification('error', message)

  abrirNotificacaoInfo = (message) => Notification.createNotification('info', message);

  render() {
    return (
      <div className="wrapper background-evoluire login-container">
        <div className="center-panel background-central-panel">
          <div className="login-logo">
            <div className="app-login">
              <div className="logo-img">
                <img src={logoImg} alt="react-logo" style={{ maxWidth: '66%' }} />
              </div>
            </div>
          </div>
          <p className="login-text font-color">Log In</p>
          <Form onSubmit={this.submitLogin} >
            <FormGroup>
              <Input
                type="text"
                name="username"
                id="username"
                placeholder="Digite seu nome de usuário"
                onChange={this.handleInputChange}
                required={true}
                minLength="3" />
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Senha"
                onChange={this.handleInputChange}
                required={true}
                minLength="6" />
              <Button color="success" type="submit">
                Acessar
            </Button>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
  }
}

export default Login;
